.live-dashboard {
  max-width: 90%;
  margin: 0 auto;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 1.1rem;
}

.stat-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-top: 0.5rem;
}

.workflow-states {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
}

.state-card {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
}

.recent-activity {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  margin-top: 2rem;
  height: calc(100vh - 400px);
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

.recent-activity h2 {
  color: white;
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  flex-shrink: 0;
}

.activity-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 0.75rem;
  padding: 0 0.5rem;
  overflow-y: auto;
  flex-grow: 1;
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;     /* Hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.activity-list::-webkit-scrollbar {
  display: none;
}

.activity-item {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  padding: 0.8rem;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.activity-content {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.timestamp {
  color: #ffd700;
  font-size: 1rem;
  font-weight: bold;
}

.workflow-name {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  overflow: hidden;
}

.customer-name {
  color: #d1d1d1;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.error {
  color: #d32f2f;
}

.fade-in {
  animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Style scrollbar for activity list */
.activity-list::-webkit-scrollbar {
  width: 8px;
}

.activity-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.activity-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.activity-list::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}

@keyframes pulse {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(0, 255, 0, 0.1);
  }
  100% {
    background-color: transparent;
  }
}

.stat-card.pulse {
  animation: pulse 1s ease-in-out;
}

.activity-item.status-created {
  border-left: 4px solid #3498db;  /* blue */
}

.activity-item.status-warning {
  border-left: 4px solid #ff9800;  /* orange */
}

.activity-item.status-waiting {
  border-left: 4px solid #9c27b0;  /* purple */
}

.activity-item.status-completed {
  border-left: 4px solid #2ecc71;  /* green */
}

.activity-item.status-failed {
  border-left: 4px solid #e74c3c;  /* red */
}

.dashboard-logo {
    height: 100px;
}

.scroll-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.scroll-text {
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

/* Only add padding and duplicate content when scrolling is needed */
.scroll-container.needs-scroll .scroll-text {
  padding-right: 50px;
  animation: scroll-text 15s linear infinite;
  animation-delay: 4s; /* Add  second delay before animation starts */
}

.scroll-container.needs-scroll .scroll-text::after {
  content: attr(data-content);
  position: absolute;
  left: 100%;
  top: 0;
  white-space: nowrap;
  padding-right: 50px;
}

@keyframes scroll-text {
  0%, 26.67% {  /* Hold position for 4s (26.67% of 15s) */
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
